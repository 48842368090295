import { UserType } from '@/api/auth/types';
import { NavItem } from '../../types';

const sidebarConfig: NavItem[] = [
  {
    title: 'dashboard',
    path: '/app',
    icon: 'dashboard',
    roleTag: 'dashboard',
    userType: [UserType.ISSUER, UserType.ISSUER_LIMITED, UserType.ADMIN, UserType.ADMIN_LIMITED]
  },
  {
    title: 'bills',
    path: '/bills',
    icon: 'bills',
    roleTag: 'bills',
    userType: [UserType.ISSUER, UserType.ISSUER_LIMITED, UserType.ADMIN, UserType.ADMIN_LIMITED]
  },
  {
    title: 'cashiers',
    path: '/cashiers',
    icon: 'cashiers',
    roleTag: 'cashiers',
    isMerchant: true,
    userType: [UserType.ISSUER, UserType.ADMIN, UserType.ADMIN_LIMITED]
  },
  {
    title: 'sub agents',
    path: '/sub-agents',
    icon: 'subagent',
    roleTag: 'sub-agents',
    isMerchant: false,
    userType: [UserType.ISSUER, UserType.ADMIN, UserType.ISSUER_LIMITED]
  },
  {
    title: 'merchants kyc',
    path: '/merchants-kyc',
    icon: 'kyc',
    roleTag: 'merchants-kyc',
    isMerchant: true,
    userType: [UserType.ISSUER, UserType.ADMIN, UserType.ADMIN_LIMITED]
  },
  {
    title: 'withdrawal',
    path: '/withdrawal',
    icon: 'withdrawal',
    roleTag: 'withdrawal',
    userType: [UserType.ISSUER, UserType.ISSUER_LIMITED, UserType.ADMIN, UserType.ADMIN_LIMITED]
  },
  {
    title: 'deductions',
    path: '/deductions',
    icon: 'deduction',
    roleTag: 'deductions',
    userType: [UserType.ISSUER, UserType.ISSUER_LIMITED, UserType.ADMIN, UserType.ADMIN_LIMITED]
  },
  {
    title: 'devices',
    path: '/devices',
    icon: 'devices',
    roleTag: 'devices',
    userType: [UserType.ISSUER, UserType.ISSUER_LIMITED, UserType.ADMIN, UserType.ADMIN_LIMITED]
  },
  {
    title: 'gift card',
    path: '/giftcard',
    icon: 'giftcard',
    roleTag: 'giftcard',
    userType: [UserType.ISSUER, UserType.ISSUER_LIMITED, UserType.ADMIN, UserType.ADMIN_LIMITED]
  },
  {
    title: 'interstate',
    path: '/interstate/pending-tickets',
    icon: 'interstate',
    roleTag: 'interstate',
    userType: [UserType.ISSUER, UserType.ISSUER_LIMITED, UserType.ADMIN, UserType.ADMIN_LIMITED]
  },
  {
    title: 'interstate trips',
    path: '',
    icon: 'interstate',
    userType: [UserType.ISSUER, UserType.ISSUER_LIMITED, UserType.ADMIN, UserType.ADMIN_LIMITED],
    isMerchant: true,
    roleTag: 'interstate-trip',
    children: [
      {
        title: 'trips',
        path: '/interstate/trips',
        roleTag: 'interstate-trip',
        icon: '',
        userType: [UserType.ISSUER, UserType.ISSUER_LIMITED, UserType.ADMIN, UserType.ADMIN_LIMITED]
      },
      {
        title: 'manage terminals',
        path: '/interstate/terminals',
        roleTag: 'interstate-trip',
        icon: '',
        userType: [UserType.ISSUER, UserType.ISSUER_LIMITED, UserType.ADMIN, UserType.ADMIN_LIMITED]
      },
      {
        title: 'manage routes',
        path: '/interstate/routes',
        roleTag: 'interstate-trip',
        icon: '',
        userType: [UserType.ISSUER, UserType.ISSUER_LIMITED, UserType.ADMIN, UserType.ADMIN_LIMITED]
      },
      {
        title: 'manifests',
        path: '/interstate/manifests',
        roleTag: 'interstate-trip',
        icon: '',
        userType: [UserType.ISSUER, UserType.ISSUER_LIMITED, UserType.ADMIN, UserType.ADMIN_LIMITED]
      }
    ]
  },

  {
    title: 'invoice',
    path: '/invoice',
    roleTag: 'interstate-trip',
    icon: 'activity',
    userType: [UserType.ISSUER, UserType.ISSUER_LIMITED, UserType.ADMIN, UserType.ADMIN_LIMITED]
  },
  {
    title: 'transactions',
    path: '/transactions',
    icon: 'transactions',
    roleTag: 'transactions',
    userType: [UserType.ISSUER, UserType.ISSUER_LIMITED, UserType.ADMIN, UserType.ADMIN_LIMITED]
  },
  {
    title: 'discounts',
    path: '/discounts',
    icon: 'discounts',
    roleTag: 'discounts',
    isMerchant: true,
    userType: [UserType.ISSUER, UserType.ISSUER_LIMITED]
  },
  {
    title: 'pos receipt',
    path: '/pos-receipt',
    icon: 'pos',
    roleTag: 'pos-receipt',
    userType: [UserType.ISSUER, UserType.ISSUER_LIMITED, UserType.ADMIN, UserType.ADMIN_LIMITED]
  },
  {
    title: 'product category',
    path: '/product-category',
    icon: 'product',
    roleTag: 'departments',
    userType: [UserType.ISSUER, UserType.ISSUER_LIMITED, UserType.ADMIN, UserType.ADMIN_LIMITED]
  },

  {
    title: 'activity log',
    path: 'activity-log',
    icon: 'activity',
    roleTag: 'activities-log',
    userType: [UserType.ISSUER, UserType.ISSUER_LIMITED, UserType.ADMIN, UserType.ADMIN_LIMITED]
  },
  {
    title: 'access control',
    path: '/access-control',
    icon: 'access',
    roleTag: 'access-control',
    userType: [UserType.ISSUER, UserType.ISSUER_LIMITED, UserType.ADMIN, UserType.ADMIN_LIMITED]
  },
  {
    title: 'api keys',
    path: '/api-keys',
    icon: 'api-keys',
    roleTag: 'api-keys',
    isMerchant: true,
    userType: [UserType.ISSUER, UserType.ADMIN, UserType.ADMIN_LIMITED]
  },

  {
    title: 'account-settings',
    path: '/account-settings',
    icon: 'account-settings',
    roleTag: 'account-settings',
    isMerchant: true,
    userType: [UserType.ISSUER, UserType.ADMIN, UserType.ADMIN_LIMITED]
  }
];

export default sidebarConfig;
